<template>
  <div>
    <b-card>
      <b-form @submit.prevent>
        <b-row>
          <b-col cols="12">
            <b-form-group
                label="Müşteri Seçiniz:"
                label-for="customer"
            >
              <b-form-input
                  v-model="customerName"
                  disabled
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group
                label="Kargo Kodu:"
                label-for="cargo"
            >
              <b-form-input
                  id="cargo"
                  placeholder="Kargo kodu"
                  v-model="cargoCode"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <b-form-group
                label="Tutar:"
                label-for="2"
            >
              <v-select
                  id="2"
                  placeholder="Sipariş Türü seçiniz.."
                  v-model="orderType"
                  :getValue="val => val.value"
                  :getOptionLabel="val => val.text"
                  :options="orderTypeOptions"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
                label="Tutar:"
                label-for="1"
            >
              <b-input-group
                  append="₺"
              >
                <b-form-input
                    id="1"
                    type="number"
                    placeholder="Tutar"
                    v-model="typePrice"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row
            v-for="(item, index) in details"
            :id="item.id"
            :key="item.id"
            ref="row"
        >

          <!-- Item Name -->
          <b-col md="8">
            <b-form-group
                label="Ürün Seçiniz:"
                label-for="product"
            >
              <v-select
                  id="product"
                  placeholder="Ürün seçiniz.."
                  v-model="item.productId"
                  :getOptionLabel="product => product.title"
                  :options="productOptions"
              />
            </b-form-group>
          </b-col>

          <b-col md="2">
            <b-form-group
                label="Adet"
                label-for="quantity"
            >
              <b-form-input
                  id="quantity"
                  type="number"
                  placeholder="1"
                  v-model="item.quantity"
              />
            </b-form-group>
          </b-col>

          <!-- Remove Button -->
          <b-col
              lg="2"
              md="2"
              class="mb-50"
          >
            <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                class="mt-0 mt-md-2"
                @click="removeItem(index)"
            >
              <feather-icon
                  icon="XIcon"
                  class="mr-25"
              />
            </b-button>
          </b-col>
          <b-col cols="12">
            <hr>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols=6>
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1"
                @click="repeateAgain"
            >
              <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
              />
              Ürün Ekle
            </b-button>
          </b-col>
          <b-col cols="6" style="text-align:right;">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                @click="saveOrder"
            >
              Siparişi Kaydet
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BBadge,
  BForm,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from "axios";
import vSelect from 'vue-select'
import {mapGetters} from "vuex";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BBadge,
    BForm,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    Order: Object,
  },
  data() {
    return {
      cargoCode: "",
      typePrice: 0.0,
      orderType: 0,
      productOptions: [],
      customerName: "",
      customerId: 0,
      orderGroup: "",
      orderTypeOptions: [{text: 'Seçilmemiş', value: 0}, {text: 'Eft', value: 1}, {text: 'Kapıda', value: 2}],
      details: [{
        id: 1,
        productId: 0,
        quantity: null,
      }],
      nextTodoId: 2,
    }
  },
  computed: {
    ...mapGetters({
      selectedOrder: "token/getOrderSelect"
    })
  },
  mounted() {
  },
  created() {
    if (this.selectedOrder === null || this.selectedOrder === undefined) {
      this.$router.push('/orderList')
      return
    }

    this.orderGroup = this.selectedOrder.orderGroup
    this.customerId = this.selectedOrder.customerIdd
    this.orderType = this.selectedOrder.orderType === "Eft" ? this.orderTypeOptions.find(val => val.value === 1) : this.selectedOrder.orderType === "Kapıda" ? this.orderTypeOptions.find(val => val.value === 2) : this.orderTypeOptions.find(val => val.value === 0)
    this.cargoCode = this.selectedOrder.cargoCode
    this.customerName = this.selectedOrder.customerId
    this.typePrice = this.selectedOrder.typePrice

    axios.post('/order/detail', {
      orderGroup: this.selectedOrder.orderGroup
    })
        .then(response => {
          this.selectedOrderDetail = response.data.data

          axios.get('/product/list')
              .then(response => {
                this.productOptions = []
                if (response.data.data !== null && response.data.data.length > 0) {
                  response.data.data.forEach(r => {
                    this.productOptions.push({
                      val: r.id,
                      title: r.name + ' - ' + (r.description.length > 20 ? r.description.substring(0, 20) + '...' : r.description) + ' - ' + r.quantity + ' Adet'
                    })
                  })
                }

                this.selectedOrderDetail.forEach((key, index) => {
                  if (index > 0)
                    this.repeateAgain()

                  if (index === 0) {
                    this.details.find(y => y.id === (index + 1)).quantity = Number(key.quantity)
                    this.details.find(y => y.id === (index + 1)).productId = this.productOptions.find(prdct => prdct.val === key.productId)
                  } else {
                    this.details.find(y => y.id === Math.pow(2,(index + 1))).quantity = Number(key.quantity)
                    this.details.find(y => y.id === Math.pow(2,(index + 1))).productId = this.productOptions.find(prdct => prdct.val === key.productId)
                  }

                });
              })
              .catch()
        })
        .catch()

    this.$store.dispatch('token/changeOrderSelect', null)
  },
  destroyed() {
  },
  methods: {
    repeateAgain() {
      this.details.push({
        id: this.nextTodoId += this.nextTodoId,
        quantity: null,
        productId: 0,
      })
    },
    removeItem(index) {
      this.details.splice(index, 1)
    },
    saveOrder() {
      const orderModal = {
        customerId: this.customerId,
        orderGroup: this.orderGroup,
        cargoCode: this.cargoCode,
        orderType: this.orderType.value,
        typePrice: this.typePrice,
        details: this.details.map(r => ({
          productId: r.productId.val,
          quantity: parseInt(r.quantity)
        }))
      };

      axios.put('/order/update', orderModal).then(response => {
        this.$toastApiResponse(response.data)
        this.$router.push('/orderList')
      })
          .catch(err => {
            this.$toastBaha(err.message, 'danger')
          })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
